import React from "react"

export const Testimonials = () => {
  return (
    <>
      <div className="text-center text-3xl font-extrabold mx-auto">
        <h3 className="prose text-3xl">Testimonials</h3>
      </div>
      <section className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
          <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-gray-300 lg:pr-16">
            <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg leading-7 font-medium text-gray-500 md:flex-grow">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="prose relative">
                  Zach has done three home inspections for me. One of them was
                  the reason I didn’t buy the house. The second one the owner
                  changed their mind. The third one was the House I bought. I
                  called and he scheduled it and went the next day and emailed
                  me the report the same day. I was able to call him and he went
                  over the report with me. He is very thorough and was very
                  prompt. I appreciated that. I would totally recommend this
                  place.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="ml-4">
                    <div className="text-base leading-6 font-medium text-gray-500">
                      - Mary K.
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
          <div className="py-12 px-4 border-t-2 border-gray-300 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
            <blockquote className="mt-8 md:flex-grow md:flex md:flex-col">
              <div className="relative text-lg leading-7 font-medium text-gray-500 md:flex-grow">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="prose relative">
                  I am a local realtor with Crye-leike and I highly recommend
                  Zach at Elite Inspections. Zach is great to work with along
                  with that he does his job in a timely, reliable manner, and
                  very impressed with his reports, very thorough. Great pricing
                  doesn’t hurt either. Check him out.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex">
                  <div className="ml-4">
                    <div className="text-base leading-6 font-medium text-gray-500">
                      - Melissa Ellis/Realtor
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </>
  )
}
